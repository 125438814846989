import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { catchError, takeUntil, switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

import { UmbrellaService } from '../../services/umbrella.service';

@Component({
  selector: 'app-search-umbrella-travellers',
  templateUrl: './search-umbrella-travellers.component.html',
  styleUrls: ['./search-umbrella-travellers.component.scss']
})
export class SearchUmbrellaTravellersComponent implements OnInit, OnDestroy {
  @Input() target = '';
  @Input() travellerInputValue = '';
  @Input() passengerFormKey: string;
  @Input() isDisabled: boolean;
  @Input() isInvalid: boolean;
  @Input() isValid: boolean;
  @Input() noInputFocus;
  @Output() emitPsgFormData = new EventEmitter();
  @Output() emitSearchString = new EventEmitter();

  travellersResponseData: any;
  travellersSearchPlaceholder = "SEARCH_UMBRELLA_TRAVELERS.PLACEHOLDER";
  travellersSelectData: any[] = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(public umbrellaService: UmbrellaService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  /**
   * get Umbrella travellers
   * @param event
   */
  getUmbrellaTravellers(event) {
    this.umbrellaService.getTravelers(event, this.target)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(error => this.handleUmbrellaAuthErrors(error, event))
      )
      .subscribe((res: any) => {
        if (res?.results) {
          this.travellersResponseData = res;
          this.travellersSelectData = this.travellersResponseData.results.map( val => {
            return {
              id: val.uuid,
              text: val.fullName
            };
          });
        } else {
          this.umbrellaService.redirectToUmbrellaLogin();
        }
      });
  }

  private handleUmbrellaAuthErrors(error, event) {
    if (error.status === 401) {
      return this.umbrellaService.getRefreshToken().pipe(
        switchMap(() => this.umbrellaService.getTravelers(event, this.target)),
        catchError(() => {
          this.umbrellaService.redirectToUmbrellaLogin();
          return of([]);
        })
      );
    }
    this.umbrellaService.redirectToUmbrellaLogin();
    return of([]);
  }

  /**
   * emitSelectedItem
   * @param event
   */
  onEmitSelectedTraveller(event) {
    this.getUmbrellaTravellers(event);
    this.emitSearchString.emit(event);
  }

  /**
   * on select Umbrella traveller
   * @param event
   */
  onSelectedTraveller(event) {
    if (event) {
      this.getTravellerProfileData(event);
    } else {
      const travellerData = {
        responseData: null,
        passengerKey: this.passengerFormKey
      };
      this.emitPsgFormData.emit(travellerData);
    }
  }

  /**
   * get Umbrella travellers profile
   * @param traveller
   */
  private getTravellerProfileData(traveller) {
    this.umbrellaService.getTravelerProfile(traveller.id).subscribe(res => {
      const travellerData = {
        responseData: res,
        passengerKey: this.passengerFormKey
      };
      this.emitPsgFormData.emit(travellerData);
    });
  }


}
