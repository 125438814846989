import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ProfilesService} from '../../services/profiles.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CorporateDiscountCodesService} from '../../services/corporate-discount-codes.service';


@Component({
  selector: 'app-profiles-bar',
  templateUrl: './profiles-bar.component.html',
  styleUrls: ['./profiles-bar.component.scss']
})
export class ProfilesBarComponent implements OnInit, OnDestroy {

  @Input() leftLoader;
  @Input() noInputFocus;
  @Input() placeholderKey = 'PROFILES_BAR.PLACEHOLDER';
  @Input() useNewDesign = false;
  @Output() emitIsSelectedCompany = new EventEmitter();
  @Output() emitIsSelectedCompanyInfo = new EventEmitter();

  corporateSelectData: any[] = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();


  constructor(
    private profilesService: ProfilesService,
    private ls: LocalStorageService,
    public modalService: NgbModal,
    private corporateDiscountCodesService: CorporateDiscountCodesService,
  ) { }

  ngOnInit() {
  }

  searchCorporates(event) {
    if (!event) {
      this.corporateSelectData = [];
      return;
    }

    this.profilesService.searchCorporates(event)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body && res.body.accounts) {
          this.corporateDiscountCodesService.processCorporateCodes(res.body.accounts);

          this.corporateSelectData = res.body.accounts.map(val => {
            return {
              id: val.id,
              text: val.name,
              corporate_id: val.corporate_id,
              remarks: val.remarks,
              account_number: val.account_number,
              loyalty_program_discount_codes: val.loyalty_program_discount_codes
            };
          });
        } else {
          this.corporateSelectData = [];
        }
      }, err => console.log(err));
  }

  /**
   * emitSearchString
   * @param event
   */
  onEmitCorporateSearchString(event) {
    this.searchCorporates(event);
  }

  /**
   * emitSelectedItem
   * @param event
   */
  onSelectProfilesCompany(event) {
    if (event) {
      this.ls.setProfileCorporate(event);
      this.emitIsSelectedCompany.emit(true);
      this.emitIsSelectedCompanyInfo.emit({id: event.id, text: event.text});
    } else {
      this.ls.removeProfileCorporate();
      this.emitIsSelectedCompany.emit(false);
    }
  }

  open(content, size: 'lg' | 'sm' = 'lg', windowClass = '') {
    this.modalService.open(content, {
      size, windowClass
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
