import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpersService} from "../../shared/services/helpers.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Preference, Preset, PresetForm, DefaultQualifierTypes, DefaultQualifierNames, } from "../../shared/interfaces/presets";
import {NotificationService} from "../../shared/services/notification.service";
import {HubAgenciesService} from "../../shared/services/hub-agencies.service";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {Dictionary} from "../../shared/types/dictionary";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-preset-detail',
  templateUrl: './preset-detail.component.html',
  styleUrls: ['./preset-detail.component.scss']
})
export class PresetDetailComponent implements OnInit {

  @ViewChild('popover') popover: NgbPopover;
  id: string | number;
  preset: Preset;
  form: FormGroup;
  formControlNames: PresetForm = {
    title: 'title',
    airline: 'airline',
    travelerType: 'travelerType',
    qualifierType: 'qualifierType',
    preferenceCode: 'code',
    preferenceDefinition: 'definition',
    preferenceTitle: 'title',
  };
  validateTriggered = false;
  isNewRecord: boolean;
  isEdit = true;
  farePreferences = Dictionary.FarePreferences;
  preferenceList: Preference[];
  defaultTravelerTypes = ['ADT', 'YAD', 'CHD', 'INF'];
  travelerTypeMinLength = 2;
  travelerTypeMaxLength = 3;
  isAddNewTraveler = false;
  isAddNewPreference = false;
  unsavedChanges = false;

  buttonName = {ptcs: 'help_outline', preferences: 'help_outline', qualifier: 'help_outline', isDefault: 'help_outline', isMandatory: 'help_outline'};

  breadcrumbs = [{
    label: this.translate.instant('PRESET_LIST.BREADCRUMBS.PRESETS'),
    url: `/presets`,
    hideActive: true
  }];

  onMouseEnter(key: string) {
    this.buttonName[key] = 'help';
  }

  onMouseLeave(key: string) {
    this.buttonName[key] = 'help_outline';
  }

  @HostListener('document:click', ['$event']) onClickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target) || !event.target.className.includes('help-icon')) {
      this.buttonName = {ptcs: 'help_outline', preferences: 'help_outline', qualifier: 'help_outline', isDefault: 'help_outline', isMandatory: 'help_outline'};
    }
  }

  constructor(private fb: FormBuilder,
              public helpers: HelpersService,
              private route: ActivatedRoute,
              private router: Router,
              private _notificationSvc: NotificationService,
              public hubAgenciesService: HubAgenciesService,
              private elementRef: ElementRef,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.initId();
    this.getPreset();
  }

  initId() {
    const routeId = this.route.snapshot.paramMap.get('id');
    if (routeId === 'add') {
      this.id = routeId;
      this.isNewRecord = true;
    } else {
      this.id = parseInt(routeId) - 1;
      this.isNewRecord = false;
    }
  }

  getPreset() {
    this.hubAgenciesService.isPresetsLoaded$.subscribe(isLoaded => {
      if (isLoaded) {
        this.preset = this.hubAgenciesService.presets[this.id];
        this.buildForm();
        this.preferenceList = this.form.get('preference').value;
        this.detectUnsavedChanges();
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      title: [{value: this.preset?.title || '', disabled: !this.isEdit}, Validators.required],
      airline: [{value: this.preset?.airline || '', disabled: !this.isEdit}, Validators.required],
      travelers: this.fb.array(this.createTravelers()),
      qualifier: this.fb.group({
        type: [this.preset?.qualifier?.type || ''],
        code: [{value: this.preset?.qualifier?.code || '', disabled: !this.isEdit}],
        airline: [this.preset?.qualifier?.airline || '']
      }),
      qualifiers: this.fb.array(this.createQualifiers()),
      preference: [this.preset?.preference || [this.farePreferences[0]]],
      preferences: this.fb.array(this.createPreferences()),
      taxExemptions: this.fb.array(this.createTaxExemptions()),
      isDefault: [{value: this.preset?.isDefault || false, disabled: !this.isEdit}],
      isMandatory: [{value: this.preset?.isMandatory || false, disabled: !this.isEdit}],
    });
  }

  createTravelers() {
    let travelers;
    if (this.preset?.travelers && this.preset.travelers.length) {
      travelers = this.preset.travelers.map(traveler => this.buildTraveler(traveler.travelerType, traveler.defaultType));
    } else {
      travelers = this.defaultTravelerTypes.map(travelerType => this.buildTraveler(travelerType, travelerType));
    }
    return travelers;
  }

  buildTraveler(travelerType = '', defaultType = 'ADT'): FormGroup {
    return this.fb.group({
      travelerType: [
        travelerType,
        [
          Validators.required,
          Validators.minLength(this.travelerTypeMinLength),
          Validators.maxLength(this.travelerTypeMaxLength),
          Validators.pattern(/^[a-zA-Z]+$/)
        ]
      ],
      defaultType: [defaultType, [Validators.required]]
    });
  }

  onTravelerTypeInputChange(value: string, control: FormControl) {
    control.get('travelerType').setValue(value.toUpperCase());
  }

  addNewTraveler(defaultType: string) {
    this.isAddNewTraveler = true;
    const control = this.buildTraveler('', defaultType);
    const travelersArray = this.form.get('travelers') as FormArray;
    travelersArray.push(control);
    travelersArray.markAsDirty();
  }

  removeTraveler(index: number) {
    const arrayControl = this.form.get('travelers') as FormArray;
    arrayControl.removeAt(index);
    arrayControl.markAsDirty();
    this.isAddNewTraveler = false;
  }

  saveTraveler(control: FormGroup, currentIndex: number) {
    const arrayControl = this.form.get('travelers') as FormArray;
    const existingIndexControl = arrayControl.value.findIndex(val => val.defaultType === control.value.defaultType);

    if (existingIndexControl !== -1 && existingIndexControl !== currentIndex) {
      arrayControl.at(existingIndexControl).setValue(control.value);
      arrayControl.removeAt(currentIndex);
    }

    this.isAddNewTraveler = false;
  }

  createQualifiers() {
    let qualifiers;
    if (this.preset?.qualifiers && this.preset.qualifiers.length) {
      qualifiers = this.preset.qualifiers.map(qualifier => this.buildQualifier(qualifier.qualifierType));
    } else {
      qualifiers = DefaultQualifierTypes.map(qualifierType => this.buildQualifier(qualifierType));
    }

    return qualifiers;
  }

  buildQualifier(qualifierType = '') {
    return this.fb.group({
      qualifierType: [qualifierType, [Validators.required]]
    });
  }

  buildQualifierName(type: string) {
    return DefaultQualifierNames[type];
  }

  selectQualifierType(type: string) {
    const qualifierTypeControl = this.form.get('qualifier').get('type');
    qualifierTypeControl.setValue(type);
    qualifierTypeControl.markAsDirty();
  }

  createPreferences() {
    let preferences;
    if (this.preset?.preferences && this.preset.preferences.length) {
      preferences = this.preset.preferences.map(preference => this.buildPreference(preference));
    } else {
      preferences = this.farePreferences.map(preference => this.buildPreference(preference));
    }

    return preferences;
  }

  buildPreference(preference: Preference = {code: '', definition: '', title: ''}) {
    return this.fb.group({
      code: [preference.code],
      definition: [preference.definition],
      title: [preference.title, [Validators.required]],
    });
  }

  onSelectedPreference(preference) {
    const existItem =
      this.preferenceList.findIndex((item) => item.title === preference.title) !== -1;
    const hasNoPreferences =
      this.preferenceList.findIndex((item) => item.title === 'No Preference') !== -1;

    if (preference.title === 'No Preference') {
      this.preferenceList = [preference];
      this.form.get('preference').setValue(this.preferenceList);
      return;
    }

    if (!existItem) {
      this.preferenceList = hasNoPreferences
        ? [preference]
        : [...this.preferenceList, preference];
    } else {
      this.preferenceList = this.preferenceList.filter(
        (item) => item.title !== preference.title
      );
    }
    this.form.get('preference').setValue(this.preferenceList);
    this.form.get('preferences').markAsDirty();
  }

  addNewPreference() {
    this.isAddNewPreference = true;
    const arrayControl = this.form.get('preferences') as FormArray;
    arrayControl.markAsDirty();
    const control = this.buildPreference();
    arrayControl.push(control);
  }

  removePreference(index: number, preference?: Preference) {
    const arrayControl = this.form.get('preferences') as FormArray;
    arrayControl.removeAt(index);

    if (preference) {
      this.preferenceList = this.preferenceList.filter(
        (item) => item.title !== preference.title
      );
      if (!this.preferenceList.length) {
        this.preferenceList.push(arrayControl.value[0]);
      }
      this.form.get('preference').setValue(this.preferenceList);
    }

    this.isAddNewPreference = false;
  }

  savePreference(control: FormGroup, currentIndex: number) {
    const arrayControl = this.form.get('preferences') as FormArray;
    arrayControl.at(currentIndex).setValue(control.value);
    this.isAddNewPreference = false;
  }

  onSelectAirline(value: string) {
    this.form.get('qualifier').get('airline').setValue(value);
  }

  createTaxExemptions() {
    let taxExemptions;
    if (this.preset?.taxExemptions && this.preset.taxExemptions.length) {
      taxExemptions = this.preset.taxExemptions.map(t => this.buildTaxExemption(t.code));
    } else {
      taxExemptions = [];
    }

    return taxExemptions;
  }

  buildTaxExemption(code = '') {
    return this.fb.group({
      code: [code, [Validators.required]]
    });
  }

  addNewTaxExemption() {
    const arrayControl = this.form.get('taxExemptions') as FormArray;
    arrayControl.markAsDirty();
    const control = this.buildTaxExemption();
    arrayControl.push(control);
  }

  removeTaxExemption(idx: number) {
    const arrayControl = this.form.get('taxExemptions') as FormArray;
    arrayControl.removeAt(idx);
    arrayControl.markAsDirty();
  }

  save() {
    if (!this.validate()) {
      return;
    }
    this.updatePresets(this.form.value);
    this.form.disable();
    this.isEdit = this.unsavedChanges = false;
      this.hubAgenciesService.updatePresets(this.hubAgenciesService.presets).subscribe(response => {
        this._notificationSvc.success(
          this.translate.instant('NOTIFICATIONS.SUCCESS'),
          this.translate.instant('PRESET_DETAIL.NOTIFICATIONS.SUCCESS_SAVE')
          );
        this.navigateToPresetList();
      });
  }

  navigateToPresetList() {
    setTimeout(() => {
      this.router.navigate(['/presets']);
    }, 1000);
  }

  private validate(): boolean {
    this.validateTriggered = true;
    return this.form.valid;
  }

  updatePresets(value) {
    let presets = [...this.hubAgenciesService.presets];
    if (this.isNewRecord) {
      presets.push(value);
    } else {
      presets[this.id] = value;
    }
    this.hubAgenciesService.presets = presets;
  }

  detectUnsavedChanges() {
    let initialFormValue = this.form.value;
    this.form.valueChanges.subscribe((currentValue) => {
      if (this.isEdit) {
        this.unsavedChanges = JSON.stringify(currentValue) !== JSON.stringify(initialFormValue);
      } else {
        this.unsavedChanges = false;
      }
    });
  }

  editPreset() {
    if (!this.isEdit) {
      this.form.enable();
      this.isEdit = true;
      this.detectUnsavedChanges();
    }
  }

}
