import { Injectable } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private modalService: NgbModal, 
              private translateService: TranslateService) { }

  public confirm(
    titleKey: string,
    messageKey: string,
    btnOkTextKey: string = 'BUTTONS.PROCEED',
    btnCancelTextKey: string = 'BUTTONS.CANCEL',
    dialogSize: 'sm'|'md'|'lg' = 'md'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = this.translateService.instant(titleKey);
    modalRef.componentInstance.message = this.translateService.instant(messageKey);
    modalRef.componentInstance.btnOkText = this.translateService.instant(btnCancelTextKey);
    modalRef.componentInstance.btnCancelText = this.translateService.instant(btnCancelTextKey);
    return modalRef.result;
  }

  confirmDialog(messageKey: string = 'CONFIRM_DIALOG.UNSAVED_CHANGES_MESSAGE'): Promise<boolean | void> {
    return this.confirm('CONFIRM_DIALOG.BEFORE_CONTINUING', messageKey)
      .then((confirmed) => {
        return confirmed;
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}
