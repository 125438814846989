import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CommentsModel} from "../../../shared/models/comments.model";
import {takeUntil} from "rxjs/operators";
import {NDCApiService} from "../../../shared/services/ndc-api.service";
import {Subject} from "rxjs";
import {NotificationService} from "../../../shared/services/notification.service";
import {ErrorTypes} from '../../../shared/types/dictionary';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {

  @Input() comment = '';
  @Input() orderId = '';
  @Input() isSuccessUpdate;
  @Output() emitUpdatedComment = new EventEmitter<string>();
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  isEditing = false;
  commentValue: string;
  constructor(
    private service: NDCApiService,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  sendComment(inputElement: HTMLInputElement) {
    this.updateComment(inputElement.value.replace(/\r\n|\r|\n/g, "<br />"));
    this.isEditing = false;
  }

  updateExistingComment(inputElement: HTMLInputElement) {
    this.isEditing = !this.isEditing;
    inputElement.value = this.comment.replace(/<br\s?\/?>/g, "\n");
    this.commentValue = inputElement.value;
    this.comment = '';
  }

  updateComment(comment: string): void {
    const sendData: CommentsModel = {
      id: this.orderId,
      comments: comment
    };
    this.service.updateOrderComment(sendData)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.emitUpdatedComment.emit(comment);
        if (response && response.ok && this.commentValue !== comment) {
          this.notificationService.success(
            this.translate.instant('NOTIFICATIONS.SUCCESS'),
            this.translate.instant('COMMENTS.NOTIFICATIONS.COMMENTS_UPDATED'),
            8000
          );
        }
      }, res => {
        if (res.error && res.error.detail) {
          this.notificationService.error(ErrorTypes.InternalServerError, res.error.detail, res.status);
        }
      });
  }

  cancel() {
    this.isEditing = !this.isEditing;
  }
}
