import { Component, OnInit } from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {Agent} from "../../shared/types/agent";
import {Subject} from "rxjs";
import {HubAgencyService} from "../../shared/services/hub-agency.service";
import { NotificationService } from "../../shared/services/notification.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

  agents: Agent[] = [];
  copiedAgents: Agent[] = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private agencyService: HubAgencyService,
              private _notificationSvc: NotificationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.getAgents();
  }

  getAgents() {
    this.agencyService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body) {
          this.agents = res.body.filter((agent: Agent) => agent.account_type === 'agent');
          this.copiedAgents = JSON.parse(JSON.stringify(this.agents));
        }
      }, err => this._notificationSvc.error(
        this.translate.instant('NOTIFICATIONS.ERROR'), 
        this.translate.instant('AGENT_LIST.NOTIFICATIONS.FAILED_LOAD_AGENTS')
      ));
  }

}
