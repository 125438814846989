import { Component, OnInit } from '@angular/core';
import {Agent} from "../../../shared/types/agent";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HubAgenciesService} from "../../../shared/services/hub-agencies.service";
import {ActivatedRoute} from "@angular/router";
import {HelpersService} from "../../../shared/services/helpers.service";
import {Agency} from "../../../shared/types/agency";
import { NotificationService } from "../../../shared/services/notification.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subagency-agents',
  templateUrl: './subagency-agents.component.html',
  styleUrls: ['./subagency-agents.component.scss']
})
export class SubagencyAgentsComponent implements OnInit {

  agents: Agent[] = [];
  copiedAgents: Agent[] = [];
  agency: Agency;
  agencyId: string;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(public hubAgenciesService: HubAgenciesService,
              private route: ActivatedRoute,
              public helpers: HelpersService,
              private _notificationSvc: NotificationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.agencyId = this.route.snapshot.paramMap.get('id');
    this.getAgency();
  }

  getAgency() {
    this.hubAgenciesService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body) {
          this.agency = res.body?.find(agency => agency.id === this.agencyId);
          this.agents = this.agency?.accounts || [];
          this.copiedAgents = JSON.parse(JSON.stringify(this.agents));
        }
      }, (err) => {
        this._notificationSvc.error(
          this.translate.instant('NOTIFICATIONS.ERROR'), 
          this.translate.instant('SUBAGENCY_LIST.NOTIFICATIONS.FAILED_LOAD_AGENCY')
        )
        if (this.helpers.isCriticalError(err)) {
          throw err;
        }
      });
  }
}
