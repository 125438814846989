import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from "../../shared/services/local-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {saveAs} from "file-saver";
import {NotificationService} from "../../shared/services/notification.service";
import {HubAgenciesService} from "../../shared/services/hub-agencies.service";
import {Preset} from "../../shared/interfaces/presets";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-preset-list',
  templateUrl: './preset-list.component.html',
  styleUrls: ['./preset-list.component.scss']
})
export class PresetListComponent implements OnInit {

  defaultTravelerTypes = ['ADT', 'YAD', 'CHD', 'INF'];
  file: File;

  // pagination
  page = 1;
  pageSize = 15;

  breadcrumbs = [];

  constructor(public ls: LocalStorageService,
              private route: ActivatedRoute,
              private router: Router,
              private _notificationSvc: NotificationService,
              public hubAgenciesService: HubAgenciesService, 
              private translate: TranslateService) { }


  ngOnInit(): void {
    this.parseUrl();
    this.setupBreadcrumbs();
  }

  setupBreadcrumbs(): void {
    this.breadcrumbs = [
      {
        label: this.translate.instant('PRESET_LIST.BREADCRUMBS.PREVIOUS_PAGE'),
        backToPrevious: true
      },
      {
        label: this.translate.instant('PRESET_LIST.BREADCRUMBS.PRESETS'),
      }
    ];
  }

  editPreset(idx: number) {
    const presetId = (this.page - 1) * this.pageSize + idx + 1;
    this.router.navigate(['/presets', presetId]);
  }

  deletePreset(idx: number) {
    const presetId = (this.page - 1) * this.pageSize + idx;
    this.hubAgenciesService.presets.splice(presetId, 1);
    this.hubAgenciesService.updatePresets(this.hubAgenciesService.presets)
      .subscribe(res => {
        this._notificationSvc.success(
          this.translate.instant('NOTIFICATIONS.SUCCESS'),
          this.translate.instant('PRESET_LIST.NOTIFICATIONS.AFTER_REMOVE_SUCCESS')
          );
      }, error => {
        this._notificationSvc.error(
          this.translate.instant('NOTIFICATIONS.ERROR'),
          this.translate.instant('PRESET_LIST.NOTIFICATIONS.AFTER_REMOVE_ERROR')
          );
      });
  }

  exportPresets() {
    const exportData = this.hubAgenciesService.presets;

    saveAs(
      new Blob([JSON.stringify(exportData, null, 2)], { type: 'JSON' }), 'presets.json'
    );

    this._notificationSvc.success(
      this.translate.instant('NOTIFICATIONS.SUCCESS'),
      this.translate.instant('PRESET_LIST.NOTIFICATIONS.AFTER_EXPORT_SUCCESS')
      );
  }

  onFileChanged(e) {
    this.file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.file, "UTF-8");

    fileReader.onload = () => {
      const importedPresets = JSON.parse(String(fileReader.result));
      let found = false;

      if (Array.isArray(importedPresets)) {
        importedPresets.map(importedPreset => {
          if (!this.hubAgenciesService.presets.find(preset => JSON.stringify(preset) === JSON.stringify(importedPreset))) {
            found = true;
            this.hubAgenciesService.presets.push(importedPreset);
          }
        });
      }

      if (found) {
        this.hubAgenciesService.updatePresets(this.hubAgenciesService.presets).subscribe(response => {
          this._notificationSvc.success(
            this.translate.instant('NOTIFICATIONS.SUCCESS'),
            this.translate.instant('PRESET_LIST.NOTIFICATIONS.AFTER_IMPORT_SUCCESS')
            );
        });
      } else {
        this._notificationSvc.warning(
          this.translate.instant('NOTIFICATIONS.WARNING'),
          this.translate.instant('PRESET_LIST.NOTIFICATIONS.AFTER_IMPORT_WARNING')
          );
      }
    };

    fileReader.onerror = (error) => {
      this._notificationSvc.error(
        this.translate.instant('NOTIFICATIONS.ERROR'),
        this.translate.instant('PRESET_LIST.NOTIFICATIONS.AFTER_IMPORT_ERROR')
        );
      console.log(error);
    };

  }

  updatePreset(preset: Preset, isMandatory: boolean) {
    let clonedPresets = JSON.parse(JSON.stringify(this.hubAgenciesService.presets));

    this.updatePresetValue(preset, isMandatory);
    this.hubAgenciesService.updatePresets(this.hubAgenciesService.presets)
      .subscribe(() => {
        this.hubAgenciesService.refreshPresets();
        }, err => {
        this._notificationSvc.error(this.translate.instant('NOTIFICATIONS.ERROR'), err.error);
        this.hubAgenciesService.presets = clonedPresets;
      });
  }

  updatePresetValue(preset: Preset, isMandatory: boolean) {
    if (isMandatory) {
      preset.isMandatory = !preset.isMandatory;
      preset.isDefault = false;
    } else {
      preset.isDefault = !preset.isDefault;
      preset.isMandatory = false;
    }
  }

  parseUrl() {
    let params = this.route.snapshot.queryParams;
    this.page = parseInt(params.page) || 1;
    this.pageSize = parseInt(params.pageSize) || 15;
  }

  onPageChange() {
    let params = {};
    params['page'] = this.page;
    params['pageSize'] = this.pageSize;
    this.router.navigate([], {queryParams: params});
  }

}
