import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RemarkDataToUpdate, RemarkTextToUpdate } from '../../../shared/interfaces/remarks';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import {Subject} from "rxjs";
import {NDCApiService} from "../../../shared/services/ndc-api.service";
import {takeUntil} from "rxjs/operators";
import {ORDER_STATUS} from '../../../shared/constants';
import {NotificationService} from "../../../shared/services/notification.service";
import {ErrorTypes} from '../../../shared/types/dictionary';
import {RemarksTemplateComponent} from "../../../shared/components/remarks-template/remarks-template.component";
import {ConfirmDialogService} from "../../../shared/services/confirm-dialog.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss']
})
export class RemarksComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @Input() remark: any;
  @Input() templates: object[];
  @Input() orderId = '';
  @Input() orderInfo;
  @Input() isRemarksAfterOrderCreation = true;
  @Input() mandatoryRemarks;
  @Input() validateTriggered = false;
  @Input() saveTriggered = 0;
  @Input() additionalRemarkResult = '';
  @Input() remarkType: 'agency' | 'corporate';
  @Output() emitUpdatedRemark = new EventEmitter<RemarkDataToUpdate | RemarkTextToUpdate>();
  @Output() emitEditBothRemarks = new EventEmitter<boolean>();
  @Output() emitTemplateSelected = new EventEmitter<boolean>();
  @ViewChild(RemarksTemplateComponent)
  remarkTemplateComponent: RemarksTemplateComponent;
  readonly ORDER_STATUS = ORDER_STATUS;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  isEditing = false;

  isButtonVisible = {
    edit: false,
    cancel: false,
    save: false,
  };

  corporateRemarksComment = '#------- CORPORATE REMARKS --------\n';

  constructor(
    private service: NDCApiService,
    private notificationService: NotificationService,
    public ls: LocalStorageService,
    public modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private confirmDialogService: ConfirmDialogService,
    private translate: TranslateService
  ) { }

  textAreaValue = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.saveTriggered && this.saveTriggered) {
      if (this.remarkTemplateComponent) {
        this.sendRemark();
      }
    }
  }

  ngOnInit() {
    if (this.remark?.data?.variables && this.orderInfo.status === ORDER_STATUS.PENDING) {
      this.isButtonVisible.edit = true;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  sendRemark() {
    this.validateTriggered = true;
    if (this.templates) {
      const preparedData = this.remarkTemplateComponent.prepareDataToUpdate();

      if (preparedData !== null) {
        this.remarkTemplateComponent.remarkName = preparedData.data.name;
        if (!this.isRemarksAfterOrderCreation) {
          this.emitUpdatedRemark.emit(preparedData);
          this.emitTemplateSelected.emit(false);
        } else {
          if (this.additionalRemarkResult) {
            preparedData.result = this.remarkType === 'agency'
              ? preparedData.result + this.corporateRemarksComment + this.additionalRemarkResult
              : this.additionalRemarkResult + this.corporateRemarksComment + preparedData.result;
          }
          this.updateRemark(preparedData);
        }
        this.remarkTemplateComponent.remarkFormGroup.disable();

        this.isButtonVisible.cancel = false;
        this.isButtonVisible.edit = true;
        this.isButtonVisible.save = false;

      } else if (!this.isRemarksAfterOrderCreation) {
        this.emitUpdatedRemark.emit(null);
      }
    } else {
      const preparedData = this.prepareTextToUpdate();
      if (this.isRemarksAfterOrderCreation) {
        this.updateRemark(preparedData);
      } else {
        this.emitUpdatedRemark.emit(preparedData);
        this.emitTemplateSelected.emit(false);
      }
      this.isEditing = !this.isEditing;
    }
  }

  enableForm() {
    if (this.isRemarksAfterOrderCreation && this.remark?.result?.includes(this.corporateRemarksComment)) {
      const messageKey = 'REMARKS.RESET_REMARKS_WARNING';
      this.confirmDialogService.confirmDialog(messageKey).then(res => {
        if (res) {
          this.emitEditBothRemarks.emit(true);
        }
      });
    } else {
      this.remarkTemplateComponent.enableForm();
      this.isButtonVisible.edit = false;
      this.isButtonVisible.cancel = true;
      this.isButtonVisible.save = true;
      this.emitTemplateSelected.emit(true);
    }
  }

  resetForm(updateNeeded = true) {
    const preparedData = this.remarkTemplateComponent.prepareDataToReset();
    if (preparedData !== null && updateNeeded) {
      if (this.isRemarksAfterOrderCreation) {
        this.updateRemark(preparedData);
      } else {
        this.emitUpdatedRemark.emit(preparedData);
      }
    }

    Object.keys(this.remarkTemplateComponent.remarkFormGroup.controls).forEach(key => {
      if (this.remarkTemplateComponent.fieldToDisable.findIndex(item => item === key) === -1) {
        this.remarkTemplateComponent.remarkFormGroup.get(key).reset();
      }
    });

    if (!this.mandatoryRemarks) {
      this.remarkTemplateComponent.selectedTemplate = '';
      this.remarkTemplateComponent.remarkName = '';
      this.remarkTemplateComponent.parsedRemarks = [];
      this.isButtonVisible.edit = this.isButtonVisible.save = this.isButtonVisible.cancel = false;
      this.emitTemplateSelected.emit(false);
    } else {
      this.enableForm();
    }

    this.validateTriggered = false;
  }

  cancelChanges() {
    let variables = [];
    if (this.remark?.data?.variables) {
      variables = Object.keys(this.remark.data?.variables);
    }

    if (variables.length !== 0) {
      this.remarkTemplateComponent.remarkFormGroup.patchValue(this.remark.data.variables);
      this.isButtonVisible.edit = true;
      this.isButtonVisible.cancel = false;
      this.isButtonVisible.save = false;
      this.validateTriggered = false;
      this.emitTemplateSelected.emit(false);
    } else {
      this.resetForm(false);
    }
  }

  selectedTemplate(event) {
    this.isButtonVisible.cancel = this.isButtonVisible.save = true;
    this.isButtonVisible.edit = false;
    this.validateTriggered = false;
    this.emitTemplateSelected.emit(true);
  }

  updatedRemark(remark: RemarkDataToUpdate | RemarkTextToUpdate) {
    if (!this.isRemarksAfterOrderCreation) {
      this.emitUpdatedRemark.emit(remark);
      this.emitTemplateSelected.emit(false);
      this.isButtonVisible.edit = true;
      this.isButtonVisible.save = this.isButtonVisible.cancel = false;
    } else {
      this.isButtonVisible.edit = false;
      this.isButtonVisible.save = this.isButtonVisible.cancel = true;
    }
  }

  updateExistingComment() {
    this.isEditing = !this.isEditing;
    this.textAreaValue = this.remark?.result?.toString().replace(/<br\s?\/?>/g, "\n");
  }

  prepareTextToUpdate() {
    const textToUpdate: RemarkTextToUpdate = {
      id: this.orderInfo.id,
      result: this.textAreaValue
    };

    return textToUpdate;
  }

  updateRemark(remark: RemarkDataToUpdate | RemarkTextToUpdate): void {
    this.service.updateOrderRemark(remark)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        this.emitUpdatedRemark.emit(remark);
        this.emitTemplateSelected.emit(false);
        if (response && response.ok) {
          this.notificationService.success(
            this.translate.instant('NOTIFICATIONS.SUCCESS'),
            this.translate.instant('REMARKS.NOTIFICATIONS.REMARKS_UPDATED'),
            8000);
        }
      }, res => {
        if (res.error && res.error.detail) {
          this.notificationService.error(ErrorTypes.InternalServerError, res.error.detail, res.status);
        }
      });
  }

  open(content) {
    this.modalService.open(content, {size: 'lg', windowClass: 'modal-reset'}).result.then((result) => {
    },
      (reason) => {
    });
  }

  get isCancelButtonDisabled() {
   return this.mandatoryRemarks && !this.remark?.result;
  }

}
