import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  allowedLanguages = [
    { value: 'en', name: 'English', flag: 'us' },
    { value: 'es', name: 'Spanish', flag: 'es' }
  ];

  constructor(private translate: TranslateService) {}

  getCurrentLanguage(): { value: string; name: string, flag: string } {
    const savedLang = localStorage.getItem('lang') ?? 'en';
    return this.allowedLanguages.find(lang => lang.value === savedLang) ?? this.allowedLanguages[0];
  }

  setLanguage(lang: { value: string; name: string; flag: string }): void {
    localStorage.setItem('lang', lang.value);
    this.translate.use(lang.value);
  }
}
