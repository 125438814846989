import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { of, Subject } from "rxjs";
import { catchError, switchMap, takeUntil } from "rxjs/operators";

import { UmbrellaService } from "../../services/umbrella.service";
import { LocalStorageService } from '../../services/local-storage.service';
import { Observable } from "rxjs/internal/Observable";

@Component({
  selector: 'app-umbrella-bar',
  templateUrl: './umbrella-bar.component.html',
  styleUrls: ['./umbrella-bar.component.scss']
})
export class UmbrellaBarComponent implements OnInit, OnDestroy {

  @Input() leftLoader;
  @Input() noInputFocus;
  @Input() useNewDesign = false;
  @Output() emitIsSelectedCompany = new EventEmitter();

  selectedCompany = '';
  companyResponseData;
  companyPlaceholder = 'UMBRELLA_BAR.PLACEHOLDER';
  companySelectData: any[] = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  isUmbrellaAuth$: Observable<boolean>;

  constructor(
    private umbrellaService: UmbrellaService,
    private ls: LocalStorageService
  ) { }

  ngOnInit() {
    this.isUmbrellaAuth$ = this.umbrellaService.isUmbrellaAuth$;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getCompanyData(event) {
    this.umbrellaService.getCompany(event)
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        catchError(error => this.handleUmbrellaAuthErrors(error, event))
      )
      .subscribe((res: any) => {
        if (res && res.results) {
          this.companyResponseData = res;
          this.companySelectData = this.companyResponseData.results.map(val => {
            return {
              id: val.uuid,
              text: val.name
            };
          });
        } else {
          this.umbrellaService.redirectToUmbrellaLogin();
        }
      });
  }


  private handleUmbrellaAuthErrors(error, event) {
    if (error.status === 401) {
      return this.umbrellaService.getRefreshToken().pipe(
        switchMap(() => this.umbrellaService.getCompany(event)),
        catchError(() => {
          this.umbrellaService.redirectToUmbrellaLogin();
          return of([]);
        })
      );
    }
    this.umbrellaService.redirectToUmbrellaLogin();
    return of([]);
  }

  /**
   * emitSearchString
   * @param event
   */
  onEmitCompanySearchString(event) {
    this.getCompanyData(event);
  }

  /**
   * emitSelectedItem
   * @param event
   */
  onSelectUmbrellaCompany(event) {
    if ( event ) {
      this.ls.setUmbrellaCompany(event);
      this.emitIsSelectedCompany.emit(true);
    } else {
     this.ls.removeUmbrellaCompany();
     this.selectedCompany = '';
     this.emitIsSelectedCompany.emit(false);
    }
  }

}
